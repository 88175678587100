import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { useCampaignPromosQuery } from "@pricing-data/hooks/queries/useCampaignPromos.query";
import { CampaignPromoType } from "@pricing-utils/types/campaignTypes";
import { AddCampaignPromoForm } from "@pricing/components/AddCampaignPromoForm/AddCampaignPromoForm";
import { EditCampaignPromoRow } from "@pricing/components/EditCampaignPromoRow/EditCampaignPromoRow";
import React, { useState } from "react";
import styled from "styled-components";

interface CampaignPromoRowProps {
  campaignPromo: CampaignPromoType;
  onEditClick: (campaignPromoId: string) => void;
}

const campaignPromosTableColumns: (keyof CampaignPromoType)[] = [
  "id",
  "code",
  "oneTimeValue",
  "recurringValue",
  "expirationDate",
];

const Container = styled.div`
  width: 100%;
`;

const StyledTable = styled(BoTable)`
  width: 100%;
`;

const CampaignPromoRow = ({
  campaignPromo,
  onEditClick,
}: CampaignPromoRowProps) => (
  <BoTableRow key={campaignPromo.id}>
    {campaignPromosTableColumns.map((column) => (
      <BoTableCell key={`${column}${campaignPromo.id}`}>
        {campaignPromo[column] || NOT_APPLICABLE_ABBREVIATION}
      </BoTableCell>
    ))}
    <BoTableCell>
      <RhButton
        data-tracking-click={{ event: "" }}
        variant="outlined"
        fullWidth={false}
        onClick={() => onEditClick(campaignPromo.id)}
        size="small"
      >
        <RhTypography variant="body2">Edit</RhTypography>
      </RhButton>
    </BoTableCell>
  </BoTableRow>
);

export const CampaignPromosPage = () => {
  const flash = useRhFlash();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<Record<
    string,
    boolean
  > | null>(null);

  const campaignPromosQuery = useCampaignPromosQuery();

  if (campaignPromosQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (campaignPromosQuery.isError) {
    return <BoCard>Unable to get campaign promos, please try again.</BoCard>;
  }

  const showEditFormForCampaignPromo = (campaignPromoId: string) => {
    setShowEditForm({ ...showEditForm, ...{ [campaignPromoId]: true } });
  };

  const closeEditFormForCampaignPromo = (campaignPromoId: string) => {
    setShowEditForm({ ...showEditForm, ...{ [campaignPromoId]: false } });
  };

  const handleSuccessfulUpdate = (campaignPromoId: string) => {
    flash.success("Campaign successfully updated");
    closeEditFormForCampaignPromo(campaignPromoId);
    campaignPromosQuery.refetch();
  };

  const campaignPromos = campaignPromosQuery.data;

  return (
    <Container>
      <RhFlexBox marginBottom={2}>
        <RhTypography variant="h1">Campaign Promos</RhTypography>
        <RhFlexBox marginLeft={2}>
          <RhButton
            data-tracking-click={{ event: "" }}
            onClick={() => {
              setShowForm(!showForm);
            }}
            color="primary"
            size="small"
            fullWidth={false}
          >
            {showForm ? "Close" : "+ Add Campaign Promo"}
          </RhButton>
        </RhFlexBox>
      </RhFlexBox>
      {showForm && (
        <RhFlexBox marginY={2}>
          <AddCampaignPromoForm
            handleSuccess={() => {
              campaignPromosQuery.refetch();
              setShowForm(false);
            }}
          />
        </RhFlexBox>
      )}
      <StyledTable>
        <BoTableHead>
          <BoTableRow>
            {campaignPromosTableColumns.map((column) => (
              <BoTableHeadCell key={column}>
                {column === "expirationDate"
                  ? "Reward Bonus Deadline"
                  : convertCamelCaseToSentenceCase(column)}
              </BoTableHeadCell>
            ))}
            <BoTableHeadCell>Actions</BoTableHeadCell>
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {campaignPromos.map((campaignPromo) =>
            showEditForm && showEditForm[campaignPromo.id] ? (
              <EditCampaignPromoRow
                campaignPromo={campaignPromo}
                onSuccessfulUpdate={handleSuccessfulUpdate}
                onClose={closeEditFormForCampaignPromo}
                key={`editCampaignPromo${campaignPromo.id}`}
              />
            ) : (
              <CampaignPromoRow
                campaignPromo={campaignPromo}
                onEditClick={showEditFormForCampaignPromo}
                key={campaignPromo.id}
              />
            )
          )}
        </BoTableBody>
      </StyledTable>
    </Container>
  );
};
