import { pricingApi } from "@pricing-data/api/pricingApi";
import { OfferQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateOffersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: pricingApi.offers.bulkUpdate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: OfferQueryKeys.LIST() });
    },
  });
};
