import { EM_DASH } from "@common/constants/characters.constant";
import { IdType } from "@common/types/apiTypes";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { useRenewalSegmentationOffersQuery } from "@pricing-data/hooks/queries/useRenewalSegmentationOffers.query";
import { useUtilitiesQuery } from "@pricing-data/hooks/queries/useUtilities.query";
import {
  RenewalSegmentationOffersType,
  RenewalSegmentationOrderOptions,
  RenewalSegmentationTypes,
} from "@pricing-utils/types/renewalsTypes";
import {
  Container,
  StyledBody2,
} from "@pricing/components/OffersSegmentation/OffersSegmentationTable/OffersSegmentationTable.styled";
import { OffersSegmentationUpdate } from "@pricing/components/OffersSegmentation/OffersSegmentationUpdate/OffersSegmentationUpdate";
import { SelectField } from "@pricing/components/OffersSegmentation/shared/OffersSegmentation.styled";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";

const columnHelper = createColumnHelper<RenewalSegmentationOffersType>();

const columns = [
  columnHelper.accessor("utility.name", {
    header: "Utility",
  }),
  columnHelper.accessor("type", {
    header: "Type",
  }),
  columnHelper.accessor("offerSixtyDays", {
    cell: (props) => {
      const offer = props.getValue();

      if (!offer) {
        return EM_DASH;
      }

      return (
        <>
          <StyledBody2>{offer.campaignSlug}</StyledBody2>
          <StyledBody2>{offer.title}</StyledBody2>
          <StyledBody2>{formatDollarsToCents(offer.price2000Kwh)}</StyledBody2>
        </>
      );
    },
    header: "Offer 60 Days",
  }),
  columnHelper.accessor("offerThirtyDays", {
    cell: (props) => {
      const offer = props.getValue();

      if (!offer) {
        return EM_DASH;
      }

      return (
        <>
          <StyledBody2>{offer.campaignSlug}</StyledBody2>
          <StyledBody2>{offer.title}</StyledBody2>
          <StyledBody2>{formatDollarsToCents(offer.price2000Kwh)}</StyledBody2>
        </>
      );
    },
    header: "Offer 30 Days",
  }),
  columnHelper.accessor("offerExpiration", {
    cell: (props) => {
      const offer = props.getValue();

      if (!offer) {
        return EM_DASH;
      }

      return (
        <>
          <StyledBody2>{offer.campaignSlug}</StyledBody2>
          <StyledBody2>{offer.title}</StyledBody2>
          <StyledBody2>{formatDollarsToCents(offer.price2000Kwh)}</StyledBody2>
        </>
      );
    },
    header: "Offer Expiration",
  }),
  columnHelper.accessor("myAccountCampaign.campaignSlug", {
    header: "My Account Campaign",
  }),
  columnHelper.display({
    cell: (props) => {
      const test = props.row.original;

      return <OffersSegmentationUpdate renewalOffersSegmentation={test} />;
    },
    header: "Edit",
  }),
];

export const OffersSegmentationTable = () => {
  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });

  const [filterSegmentationType, setFilterSegmentationType] = useState<
    RenewalSegmentationTypes | undefined
  >(undefined);
  const [filterUtility, setFilterUtility] = useState<IdType>("");

  const segmentationOffersQuery = useRenewalSegmentationOffersQuery({
    queryOptions: {
      keepPreviousData: true,
    },
    searchOptions: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      ordering: RenewalSegmentationOrderOptions["Utility A->Z"],
      type: filterSegmentationType,
      utilityId: filterUtility,
    },
  });

  const utilitiesQuery = useUtilitiesQuery();

  if (segmentationOffersQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (segmentationOffersQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch segmentation offers</Body1>
      </BoCard>
    );
  }

  const pageCount = segmentationOffersQuery.data?.count
    ? Math.ceil(segmentationOffersQuery.data.count / pageSize)
    : 0;

  const utilityOptions =
    utilitiesQuery.data?.map((utility) => {
      return (
        <option value={utility.id} key={utility.id}>
          {utility.name}
        </option>
      );
    }) ?? [];

  return (
    <>
      <Container>
        <SelectField>
          Segmentation Type
          <select
            onChange={(event) =>
              setFilterSegmentationType(
                event.currentTarget.value as RenewalSegmentationTypes
              )
            }
          >
            <option value="">---</option>
            {Object.values(RenewalSegmentationTypes).map((value) => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </SelectField>
        <SelectField>
          Utility
          <select
            onChange={(event) => setFilterUtility(event.currentTarget.value)}
          >
            <option value="">---</option>
            {utilityOptions}
          </select>
        </SelectField>
      </Container>
      <BoReactTable<RenewalSegmentationOffersType>
        columns={columns}
        data={segmentationOffersQuery.data.results}
        tableCaption="Renewal Segmentation Offers"
        pagination
        pageCount={pageCount}
        pageSize={pageSize}
        setPagination={setPagination}
        pageIndex={pageIndex}
        rowId="id"
      />
    </>
  );
};
