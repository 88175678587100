import { IdType } from "@common/types/apiTypes";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlanQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePlanProps {
  planId: IdType;
}

export const usePlanQuery = ({ planId }: UsePlanProps) => {
  return useQuery({
    queryFn: () => pricingApi.plans.retrieve(planId),
    queryKey: PlanQueryKeys.planById(planId),
  });
};
