import { ediApi } from "@common/api/ediApi";
import { UtilityQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useUtilitiesQuery = () => {
  return useQuery({
    queryFn: ediApi.utilities.index,
    queryKey: UtilityQueryKeys.UTILITIES,
    staleTime: 10000,
  });
};
