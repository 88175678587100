import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { yesNo } from "@ops-design-system/utils/booleanHelpers";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useOffersQuery } from "@pricing-data/hooks/queries/useOffers.query";
import { OfferOrderOptions, OfferType } from "@pricing-utils/types/offerTypes";
import { OfferBulkUpdateForm } from "@pricing/components/OfferBulkUpdateForm/OfferBulkUpdateForm";
import { IndeterminateCheckbox } from "@pricing/components/OffersBulkUpdate/IndeterminateCheckbox";
import { HiddenLabel } from "@pricing/components/OffersBulkUpdate/OfferBulkUpdate.styled";
import {
  OfferSearchForm,
  OfferSearchFormValues,
} from "@pricing/components/OfferSearchForm/OfferSearchForm";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  max-height: min-content;
  width: 200px;
`;

const StyledBoLabel = styled(BoLabel)`
  align-items: center;
  display: inline-flex;
  gap: ${rhOpsSpacingPx(1)};
  height: min-content;
  &:last-of-type {
    margin-left: auto;
    margin-right: 100px;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const columns: ColumnDef<OfferType>[] = [
  {
    cell: ({ row }) => {
      const rowId = `row-select-offer-${row.id}`;
      const labelText = `Select Offer ${row.id}`;

      return (
        <>
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            id={rowId}
          />
          <HiddenLabel htmlFor={rowId}>{labelText}</HiddenLabel>
        </>
      );
    },
    header: ({ table }) => (
      <IndeterminateCheckbox
        // selects all rows from current page
        checked={table.getIsAllPageRowsSelected()}
        indeterminate={table.getIsSomePageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
        label="Select All"
      />
    ),
    id: "select",
  },
  {
    accessorKey: "id",
    header: "Id",
  },
  {
    accessorKey: "brandSlug",
    header: "Brand",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "campaignSlug",
    header: "Campaign",
  },
  {
    accessorKey: "priceMethod",
    header: "Price Method",
  },
  {
    accessorKey: "price",
    header: "Price",
  },
  {
    accessorKey: "termMonths",
    header: "Term",
  },
  {
    accessorKey: "startDate",
    cell: ({ getValue }) => formatMonthDayYear(getValue<string>()),
    header: "Start Date",
  },
  {
    accessorKey: "endDate",
    cell: ({ getValue }) => formatMonthDayYear(getValue<string>()),
    header: "End Date",
  },
  {
    accessorKey: "earliestServiceStartDate",
    cell: ({ getValue }) => formatMonthDayYear(getValue<string>()),
    header: "Earliest Start Date",
  },
  {
    accessorKey: "active",
    cell: ({ getValue }) => yesNo(getValue<boolean>()),
    header: "Active",
  },
  {
    accessorKey: "offerLabels",
    header: "Offer Labels",
  },
];

export const OffersBulkUpdate = () => {
  const [searchValues, setSearchValues] = useState<
    Partial<OfferSearchFormValues>
  >({});
  const [ordering, setOrdering] = useState<OfferOrderOptions>(
    OfferOrderOptions["Campaign A->Z"]
  );

  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );

  const offersQuery = useOffersQuery({
    queryOptions: {
      // Disable by default and use refetch to submit search
      enabled: !!searchValues.plan,
      keepPreviousData: true,
    },
    searchOptions: {
      active: searchValues.active,
      limit: pageSize,
      // React Table stores pageIndex as page number (0 indexed) so we multiply by pageSize to get our offset for the api
      offset: pageIndex * pageSize,

      ordering,
      planId: searchValues.plan,
    },
  });

  useEffect(() => {
    // Don't preserve row selections across page change
    setRowSelectionState({});
  }, [pageIndex, pageSize]);

  const handleSubmit = (values: OfferSearchFormValues) => {
    // Reset row selection on submit for new table values
    setRowSelectionState({});
    setSearchValues((prevState) => {
      return {
        ...prevState,
        ...values,
      };
    });
  };

  const pageCount = offersQuery.data?.count
    ? Math.ceil(offersQuery.data.count / pageSize)
    : 0;

  const selectedOffers =
    offersQuery.data?.results.filter(
      (offer) => offer.id in rowSelectionState
    ) ?? [];

  return (
    <>
      <HeaderContainer>
        <OfferSearchForm onSubmitSuccess={handleSubmit} />

        <StyledBoLabel>
          Sort By:
          <StyledSelect
            onChange={(event) =>
              setOrdering(event.target.value as OfferOrderOptions)
            }
          >
            {Object.entries(OfferOrderOptions).map((entry) => {
              const [key, value] = entry;

              return (
                <option value={value} key={key}>
                  {key}
                </option>
              );
            })}
          </StyledSelect>
        </StyledBoLabel>
        <OfferBulkUpdateForm
          offers={selectedOffers}
          planId={searchValues.plan ?? ""}
        />
      </HeaderContainer>
      {offersQuery.isError ? <p>Error fetching offers</p> : null}
      <BoReactTable<OfferType>
        data={offersQuery.data?.results ?? []}
        columns={columns}
        tableCaption="Offers Table"
        pagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        rowSelection
        rowId="id"
        rowSelectionState={rowSelectionState}
        setRowSelection={setRowSelectionState}
      />
    </>
  );
};
