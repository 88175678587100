import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { trueWhite } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { useOffersQuery } from "@pricing-data/hooks/queries/useOffers.query";
import { CampaignType } from "@pricing-utils/types/campaignTypes";
import { PremiseRenewalsSearchType } from "@pricing-utils/types/renewalsTypes";
import { UtilityType } from "@pricing-utils/types/utilityTypes";
import { CampaignSelection } from "@pricing/components/PremiseSegmentation/CampaignSelection/CampaignSelection";
import { OffersInCampaign } from "@pricing/components/PremiseSegmentation/OffersInCampaign/OffersInCampaign";
import { OffersSelection } from "@pricing/components/PremiseSegmentation/OffersSelection/OffersSelection";
import { RenewalSegmentationWarnings } from "@pricing/constants/renewalSegmentation.constant";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface OfferViewAndSelectionProps {
  onSelectionSuccess: () => void;
  selectedPremises: PremiseRenewalsSearchType[];
  selectedUtility: UtilityType;
}

const OfferContainer = styled(BoCard)`
  background-color: rgb(250, 250, 250);
  border: none;
  display: grid;
  gap: 16px;
  grid-template-columns: 1.5fr 1fr;
  margin-bottom: 24px;

  & > ${BoCard} {
    background-color: ${trueWhite};
    border: none;
  }

  & > span {
    grid-column: span 2;
  }
`;

const CampaignContainer = styled(BoCard)`
  background-color: rgb(250, 250, 250);
  border: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > ${BoCard} {
    background-color: ${trueWhite};
    border: none;
  }
`;

const ErrorContainer = styled.div`
  margin: ${rhOpsSpacingPx(1)};
`;

export const OffersViewAndSelection = ({
  selectedPremises,
  selectedUtility,
  onSelectionSuccess,
}: OfferViewAndSelectionProps) => {
  const [{ data: campaigns, requestMonitor }, campaignApiStateSetters] =
    useAjaxState<CampaignType[]>();

  const [selectedCampaign, setSelectedCampaign] = useState<CampaignType | null>(
    null
  );

  const flash = useRhFlash();

  const offersQuery = useOffersQuery({
    queryOptions: {
      enabled: Boolean(selectedCampaign),
    },
    searchOptions: {
      active: true,
      campaignId: selectedCampaign?.id,
      limit: 100,
    },
  });

  useEffect(() => {
    campaignApiStateSetters.setPending();
    pricingApi.campaigns
      .list({ dunsNumber: selectedUtility.dunsNumber })

      .then((res) => campaignApiStateSetters.setSuccess(res.results))
      .catch((err: RhApiError) => {
        flash.error("Failed to fetch campaigns from the API");

        campaignApiStateSetters.setFailure(err);
      });
  }, [selectedUtility]);

  if (requestMonitor.isPending) {
    return <BoCircularProgress />;
  }
  if (!requestMonitor.didSucceed) {
    return <ErrorContainer>Error fetching campaigns!</ErrorContainer>;
  }
  if (!campaigns || campaigns?.length === 0) {
    return <ErrorContainer>No campaigns returned from API.</ErrorContainer>;
  }

  const warnings = () => {
    const activeWarnings: RenewalSegmentationWarnings[] = [];

    if (!selectedPremises) {
      return activeWarnings;
    }

    // Determine if there are mixed solar and non-solar premises
    const isAllSolarEligible = selectedPremises?.every(
      (premise) => premise.solarEligible === true
    );
    const isAllSolarIneligible = selectedPremises?.every(
      (premise) => premise.solarEligible === false
    );

    // If both are false then there are mixed solar and non-solar premises
    if (!isAllSolarEligible && !isAllSolarIneligible) {
      activeWarnings.push(RenewalSegmentationWarnings.MixedSolarEligible);
    }

    // Determine if there are inactive orders
    const isAllActive = selectedPremises?.every(
      (premise) => premise.activeOrderId
    );

    if (!isAllActive) {
      activeWarnings.push(RenewalSegmentationWarnings.MissingCurrentOrder);
    }

    return activeWarnings;
  };

  return (
    <>
      <Subtitle2 $fontWeight="Bold" as="h3">
        Offers View & Selection
      </Subtitle2>
      <OfferContainer>
        <BoCard>
          <OffersSelection
            campaigns={campaigns}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            selectedPremises={selectedPremises}
            onSelectionSuccess={onSelectionSuccess}
            warnings={warnings()}
          />
        </BoCard>
        <BoCard>
          <OffersInCampaign offers={offersQuery.data?.results ?? []} />
        </BoCard>
      </OfferContainer>
      <CampaignContainer>
        <Subtitle2 $fontWeight="Bold">My Account Campaigns</Subtitle2>
        <BoCard>
          <CampaignSelection
            onSelectionSuccess={onSelectionSuccess}
            campaigns={campaigns}
            selectedPremises={selectedPremises}
            warnings={warnings()}
          />
        </BoCard>
      </CampaignContainer>
    </>
  );
};
